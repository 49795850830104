.guess-skull {
}

.guess-skull .content-card {
    border-radius: 12px;
    height: 100px;
    text-align: center;
    display: inline-flex;
    border-style: solid;
    border-width: 3px;
    
    overflow-y: scroll;
    overflow-wrap: break-word;
    scrollbar-width: none;
    -ms-overflow-style: none;
}

.guess-skull .content-card::-webkit-scrollbar {
    display: none;
}

.guess-skull .proposition {
    margin-top: auto;
    margin-bottom: auto;
    font-size: 1.8rem;
}