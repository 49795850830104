.title {
    text-align: center;
    margin-bottom: -20px;
}

.title span {
    font-size: 3.5rem;
    font-weight: normal;
}

.subtitle {
    color: black;
    font-size: 1.3rem;
    font-weight: normal;
}

@media (min-width: 600px) {
    .title span {
        font-size: 4.8rem;
    }
}