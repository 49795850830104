.create-game-form {
    padding: 0 !important;
    margin-top: 15px !important;
    margin-bottom: 15px !important;
}

.create-game-form .private-label {
    font-size: 1.5rem !important;
    padding-top: 5px;
}

.create-game-form .game-name {
    font-size: 1.8rem;
}

@media (min-width: 600px) {
    .create-game-form .create-game-form {
        margin-top: 45px !important;
        margin-bottom: 45px !important;
    }
}
