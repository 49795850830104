.player-list {
}

.player-list .player {
    border-radius: 30px;
    border-style: solid;
    border-width: 2px;
    border-color: #db3e10;

    background-color: #ffffff;
    width: 95%;
    margin-bottom: 15px;
}

.player-list .player h3 {
    margin: 0;
    font-size: 2rem;
    text-align: center;
    padding: 15px 5px;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.player-list h4 {
    position: absolute;
    left: 50%;
    bottom: 0;
    transform: translate(-50%, 25%);
}

.player-list .finished {
    border-color: #1cb4e8;
}

.player-list .reconnecting {
    position: absolute;
    top: 0;
    z-index: 9;

    margin: 0;
    font-size: 2rem;
    text-align: center;

    background-color: rgba(214, 214, 214, 0.7);
}