body {
    font-family: KleponIjo, Helvetica, serif !important;
    margin: 0;
}

.main-title {
    font-family: MexicanTequila, Helvetica, serif !important;
}

.handwriting {
    font-family: GabeTheGommba, Helvetica, serif !important;
}

.revert {
    transform: scaleX(-1);
}

.light-blue {
    color: #1CB4E8;
}

.light-blue-bg {
    background-color: #1CB4E8;
}

.dark-blue {
    color: #0047A3;
}

.dark-blue-bg {
    background-color: #0047A3;
}

.orange {
    color: #DB3E10;
}

.orange-bg {
    background-color: #DB3E10;
}

.yellow {
    color: #F7B400;
}

.yellow-bg {
    background-color: #F7B400;
}

.content-card {
    background: #ffffff;
    border-radius: 30px;
    text-align: center;
}

h2 {
    margin-top: 10px;
    font-weight: normal;
    font-size: 2.2rem;
}

h3 {
    font-weight: normal;
}

form {
    width: 100%;
}