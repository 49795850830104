.game-list {
    margin-bottom: 15px !important;
}

.game-list h2 {
    margin-top: 10px;
    font-weight: normal;
    font-size: 2.2rem;
}

.game-list .game-name {
    font-size: 1.4rem !important;
}

.game-list .players-amount {
    font-size: 1.5rem !important;
}

.game-list .join-game {
    font-size: 0.8rem !important;
}

@media (min-width: 600px) {
    .game-list .game-list {
        margin-bottom: 45px !important;
    }
}