.second {
    padding: 0 !important;
    margin-top: 15px !important;
    margin-bottom: 15px !important;
}

.second h3 {
    font-size: 1.8rem;
}

@media (min-width: 600px) {
    .second {
        margin-top: 45px !important;
        margin-bottom: 45px !important;
    }
}
