.lobby {
}

.lobby span {
    font-size: 1.1em;
}

.lobby button span {
    font-size: 1.8rem;
}

.lobby .square {
    width: 100%;
}

.lobby .square:after {
    content: "";
    display: block;
    padding-bottom: 100%;
}

.lobby .player-line {
    margin-bottom: 5px !important;
}

.lobby .player-name {
    display: flex;
    width: 100%;
    height: 100%;

    white-space: nowrap;
    overflow-x: scroll;
    scrollbar-width: none;
    -ms-overflow-style: none;
}

.lobby .player-name::-webkit-scrollbar {
    display: none;
}

.lobby .player-name h3 {
    display: inline-flex;
    margin: auto;
    font-weight: normal;
    max-width: 100%;
}

.lobby h4 {
    position: absolute;
    left: 50%;
    bottom: 0;
    transform: translate(-50%, 100%);
}

.lobby .constraint-button {
    margin-top: -16px;
    font-size: 1.5rem;
}

.lobby .constraint-amount {
    font-size: 2.5rem;
}

.lobby p {
    color: #5a5a5a;
    font-size: 1.8rem;
}

.lobby h2 {
    font-size: 2.5rem;
    margin-bottom: 0;
}

.lobby h3 {
    font-size: 2rem;
    font-weight: normal;
    margin: 0;
}

.lobby .checkbox-label {
    font-size: 1.5rem !important;
    padding-top: 5px;
}

.lobby .theme-select {
    width: 100%;
}

.lobby .word-input {
    width: 100%;
    margin-top: 1rem;
    resize: vertical;
}

@media (min-width: 600px) {
    .lobby .constraint-amount-label {
        text-align: end;
    }

    .lobby .constraint-amount-selector {
        text-align: start;
    }
}