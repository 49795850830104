.player-registration {
}

.player-registration h2 {
    margin-bottom: 10px;
}

.player-registration h3 {
    margin-bottom: 0;
    margin-top: 0;
    font-size: 2rem;
}

.player-registration p {
    color: #5a5a5a;
    font-size: 1.8rem;
}

.player-registration label span {
    font-family: GabeTheGommba, Helvetica, serif !important;
    font-size: 1.5rem !important;
}