.card {
    position: relative;
    width: 100%;
}

.card img {
    max-width: 100%;
}

.card .card-foreground.game-card {
    max-height: 80%;
}

.card .card-foreground {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 9;
    transform: translate(-50%, -50%);

    width: 85%;
    max-height: 50%;

    margin: 0;
    text-align: center;

    overflow-y: scroll;
    overflow-wrap: break-word;
    scrollbar-width: none;
    -ms-overflow-style: none;
}

.card .card-foreground::-webkit-scrollbar {
    display: none;
}

.card .card-foreground h3 {
    margin: 0;
    font-size: 2rem;
    text-align: center;
}