.input-skull {
}

.input-skull h2 {
    margin: 0;
}

.input-skull span {
    font-size: 1.5rem;
    margin: 0;
}