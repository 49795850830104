.game-info-row {
    margin-top: 5px !important;
    margin-bottom: 5px !important;
}

.game-info-row span {
    font-size: 1.7rem;
}

.game-info-row h3 {
    font-size: 2rem;
}

.game-info-row .center-text {
    display: inline-flex;
    margin: auto;
}

@media (min-width: 600px) {
    .game-info-row {
        margin-top: 10px !important;
        margin-bottom: 10px !important;
    }
}